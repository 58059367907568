import { useHistory, useLocation } from 'react-router-dom'
import { useState, useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Typography, Divider } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import ReportTable from './reportOutput/ReportTable'
import ReportChart from './reportOutput/ReportChart'
import useFetch from '../../hooks/useFetch'

import './PrintStyles.css'
import DpoaeTable from './reportOutput/DpoaeTable';

interface IDoc {
  id: string,
  upload_date: string
};
interface IDocs {
  [dpoae: string]: IDoc[] | [],
  moca: IDoc[] | [],
  mmse: IDoc[] | [],
  survey: IDoc[] | [],
  audiometry: IDoc[] | [],

};
interface ITableState {
  patientId: string,
  name: string,
  nationalId: string,
  age: number,
  sex: string,
  educationLevel: number,
  center: string,
  documents: IDocs | null
};

interface IReportState {
  reportId: string | null,
  patientAge: Number | null,
  score: Number | null,
  result: boolean | null
}

interface IDpoaeData {
  left: any,
  right: any
}
interface IProps {
  token: string;
}

function Report({ token }: IProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const componentRef = useRef(null);
  const [tableState, setTableState] = useState<ITableState | null>(null);
  const [dpoaeState, setDpoaeState] = useState<IDpoaeData>({ left: undefined, right: undefined });
  const [reportState, setReportState] = useState<IReportState | null>(null);
  const [fetchState, setFetchState] = useFetch();

  const hasFetchedData = useRef(false);


  const fetchStable = useCallback((token, reportId) => {
    const url = `${process.env.REACT_APP_API_URL}v1/reports/${reportId}`;
    const headers = {
      Authorization: `JWT ${token}`
    };
    setFetchState({ url: url, method: 'GET', headers: headers }, false);
    hasFetchedData.current = true;
  }, [setFetchState])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const locationPath = location.pathname.split('/');
    const reportId = locationPath[locationPath.length - 1];
    if (!hasFetchedData.current) {
      fetchStable(token, reportId)
    }

    //setReportState({ reportId: reportId, score: null, result: null });
  }, [location, token, fetchStable])


  useEffect(() => {
    const obj = fetchState.data;
    if (obj == null) {
      return
    }
    const processedDocs = {
      dpoae: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'DPOAE'),
      moca: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'MOCA'),
      mmse: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'MMSE'),
      survey: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'SURVEY'),
      audiometry: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'AUDIOMETRY'),
    };
    const age = moment().diff(new Date(obj.patient.birth_date), 'years');
    const processedData = {
      patientId: obj.patient.id,
      name: `${obj.patient.first_name} ${obj.patient.last_name}`,
      nationalId: obj.patient.national_id,
      age: age,
      educationLevel: obj.patient.educational_level,
      sex: obj.patient.sex,
      center: obj.center ? obj.center.name : '',
      documents: processedDocs
    };

    setTableState(processedData);

    setDpoaeState({ right: obj.parsed_dpoae.parsed_file_right.data, left: obj.parsed_dpoae.parsed_file_left.data });
    //const classifierObj = obj.classifier_result.result;

    const result = obj.classifier_result.result.toLowerCase === 'true' ? true : false;

    setReportState({ reportId: obj.id, score: obj.classifier_result.score, result: result, patientAge: age });
  }, [fetchState])

  const onClick = (route: string) => history.push(route)
  /**
   * 
   * @param position Move through the anchors
   */
  const onChangeStep = (position: number) => {
    window.location.href = `#${position}`;
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <Row justify='center' align='top'>
      <Col xs={4} sm={4} lg={4}>
        <div style={{ position: 'fixed' }}>
          {
            /**
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {'Older reports'}
            </div>
            */
          }
          <div >
            {
              /**
              <Button
                onClick={() => onChangeStep(0)}
                style={{ width: '100%', minHeight: 60, whiteSpace: 'normal', marginTop: 50 }}
              >
                {t('report.hearingRisk')}
              </Button>
              */
            }
          </div>
          <div>
            <Button
              style={{ width: '100%', minHeight: 60, whiteSpace: 'normal', marginTop: 50 }}
              onClick={() => onChangeStep(1)}
            >
              {t('report.neurodegenerationRisk')}
            </Button>
          </div>
          <div>
            <Button
              type='primary'
              onClick={handlePrint}
              style={{ width: '100%', height: 40, whiteSpace: 'normal', marginTop: 50 }}
            >
              {t('commons.print')}
            </Button>
          </div>
        </div>
      </Col>

      <Col xs={20} sm={20} lg={20}>
        <div
          style={{ margin: '3%' }}
          ref={componentRef}
        >
          <div className="break-page">
            <Row justify='center' align='middle' id='0'>
              <Typography.Title level={1}>{t('report.patientData')}</Typography.Title>
            </Row>
            <Row justify='start' align='middle' id='0'>
              {`${t('report.reportDate')}:  ${moment(new Date()).format("DD/MM/YYYY")}`}
            </Row>
            <ReportTable data={tableState} />


            <Row justify='center' align='middle' gutter={[40,40]} style={{marginTop: 60}}>
              <Col xs={16} sm={10} md={10} lg={10}>
                <DpoaeTable data={dpoaeState.right} title={t('buildReport.dpoaeInputRight')} />
              </Col>

              <Col xs={16} sm={10} md={10} lg={10}>
                <DpoaeTable data={dpoaeState.left} title={t('buildReport.dpoaeInputLeft')} />
              </Col>

            </Row>


            {
              /**
                <Divider style={{ marginTop: '5%', marginBottom: '5%' }} />
  
                <Row justify='center' align='middle' id='0'>
                  <Typography.Title level={1}>{t('report.hearingRisk')}</Typography.Title>
                </Row>
  
                <Row justify='center' align='middle'>
                  <Col xs={24} sm={24} lg={24}>
                    [TODO]
                  </Col>
                </Row>
              */
            }

          </div>

          <Divider style={{ marginTop: '5%', marginBottom: '5%' }} />

          <div className="break-page">
            <Row justify='center' align='middle' id='1'>
              <Typography.Title level={1}>{t('report.neurodegenerationRisk')}</Typography.Title>
            </Row>
            <Row justify='center' align='middle'>
              <Col xs={24} sm={24} lg={24}>
                <ReportChart dataSource={reportState} />
              </Col>
            </Row>
          </div>
        </div>

        <Row justify='end' align='middle' >
          <Button type='primary' style={{ minHeight: 60, fontSize: 20, whiteSpace: 'normal', margin: 50 }} onClick={() => onClick('/buildreport')}>
            {t('commons.add')}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

const mapStatetoProps = (state: any) => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStatetoProps)(Report);