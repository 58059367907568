import React from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logoutUserAction } from '../actions/authActions'

import jwt_decode, { JwtPayload } from 'jwt-decode';
interface IProps {
  children: React.ReactNode;
  authState: any;
  logout: () => void,    
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, authState, logout, ...rest }: IProps) {

  function loadFromLocalStorage() {
    try {
      const serialisedState = localStorage.getItem("persistantState");
      if (serialisedState === null) return undefined;
      return JSON.parse(serialisedState);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }

  const checkIfAthenticated = () => {

    if (authState.authenticated) {
      const localStorage : any = loadFromLocalStorage();
      const decoded = jwt_decode<JwtPayload>(localStorage.auth.token || '') || null;
      const exp = decoded ? decoded.exp : 0;
      const expirationTime = (exp! * 1000) - 60000;
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        logout()
        return false
      }
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        checkIfAthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


const mapStatetoProps = (state: any) => {
  return {
    authState: state.auth
  }
}

const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    logout: () => {
      dispatch(logoutUserAction())
    }
  }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(PrivateRoute);
