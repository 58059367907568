import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Typography } from 'antd';
import { ResponsiveLine } from '@nivo/line'
import { BoxLegendSvg } from '@nivo/legends'
import { area, curveMonotoneX } from 'd3-shape';
import { connect } from 'react-redux';
interface IPropsArea {
  points: any,
  xScale: any,
  yScale: any
}

interface IReportState {
  reportId: string | null,
  score: Number | null,
  result: boolean | null,
  patientAge: Number | null
}

interface IProps {
  token: string,
  dataSource: IReportState | null
}

function ReportChart({ dataSource, token }: IProps) {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const headers = {
      Authorization: `JWT ${token}`
    };
    const parameters = {
      method: 'GET',
      headers: headers
    }
    Promise.all([
      fetch(`${process.env.REACT_APP_API_URL}v1/reports/constants/avg-1`, parameters),
      fetch(`${process.env.REACT_APP_API_URL}v1/reports/constants/avg`, parameters),
      fetch(`${process.env.REACT_APP_API_URL}v1/reports/constants/avg+1`, parameters),
    ]).then(async ([avgMinus, avg, avgPlus]) => {
      const a = await avgMinus.json();
      const b = await avg.json();
      const c = await avgPlus.json();
      return [a.data, b.data, c.data];
    })
      .then((result) => {
        setData(result);

      }).catch((err) => {
        setData([])
      });
  }, [token])

  const buildFlatCurve = (y: number, start: number, end: number) => {
    const emptyArray = new Array(end - start + 1).fill(undefined);
    const ret = emptyArray.map((_, idx) => {
      return {
        "x": start + idx,
        "y": y
      }
    }
    );
    return ret
  }

  // Pad with flat curves
  const buildMissingCurves = () => {
    const range = Array.from(Array(data.length).keys())
    if (range.length === 0) {
      return [];
    }
    else {
      // Build flat curves in the left and rigth side
      const flatLeft = range.map(val => ({
        "id": `flat_start_${val}`,
        "color": "rgb(180, 180, 180)",
        "data": buildFlatCurve(data[val].data[0].y, 45, 55)
      }))
      const flatRigth = range.map(val => {
        const lastIdx = data[val].data.length
        return (
          {
            "id": `flat_end_${val}`,
            "color": "rgb(180, 180, 180)",
            "data": buildFlatCurve(data[val].data[lastIdx - 1].y, 74, 100)
          }
        )
      })

      return [...flatLeft, ...flatRigth];
    }
  }

  const getEdge = (value: Number) => {
    if (value < 55) {
      return 55;
    }
    if (value > 74) {
      return 74;
    }
    return null;
  }


  const flat = buildMissingCurves();

  /**
   * Show the area where the patient was classified
   * @param param0 
   * @returns 
   */
  const AreaLayer = ({ points, xScale, yScale }: IPropsArea) => {
    let fill
    let yThreshold: Number
    if (dataSource && data.length > 0) {
      // TODO Replace this
      const age: any = dataSource.patientAge ? dataSource.patientAge : 0;
      const result = dataSource.result === null ? false : dataSource.result;

      // Has risk
      if (result) {
        const avg = data.find(obj => obj.id === 'promedio+1');
        const coords = avg.data.find((coordObj: any) => coordObj.x === age);
        const yValue = coords === undefined ? avg.data.find((coordObj: any) => coordObj.x === getEdge(age)).y : coords.y;

        points = [{ data: { x: age - .15, y: yValue } }, { data: { x: age + .15, y: yValue } }]
        fill = "rgba(227, 48, 48, .8)"
        yThreshold = .06
      }
      else {
        const avg = data.find(obj => obj.id === 'promedio-1');
        const coords = avg.data.find((coordObj: any) => coordObj.x === age);
        const yValue = coords === undefined ? avg.data.find((coordObj: any) => coordObj.x === getEdge(age)).y : coords.y;

        points = [{ data: { x: age - .15, y: yValue } }, { data: { x: age + .15, y: yValue } }]
        fill = "rgba(140, 219, 243, .8)"
        yThreshold = .0
      }

      const areaGenerator = area()
        .x((d: any) => xScale(d.data.x))
        .y0((d: any) => yScale(yThreshold))
        .y1((d: any) => yScale(d.data.y))
        .curve(curveMonotoneX);

      return <path d={areaGenerator(points)!} fill={fill} />;
    }
    return null;
  };

  const CustomLegend = ({ height, legends, width }: any) => {
    const [legend] = legends

    return (
      <>
        <BoxLegendSvg
          key="legend-1"
          {...legend}
          data={legend.data}
          containerHeight={height}
          containerWidth={width}
        />
      </>
    )
  }

  const getColor = (id:string) => {
    const colorObj: any = data.find(obj => obj.id === id);
    return colorObj === undefined ? '#FFFFFF' : colorObj.color;
  }

  const CustomLineChart = () => {
    return (
      <ResponsiveLine
        data={[...flat, ...data]}
        colors={{ datum: 'color' }}
        margin={{ top: 60, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        yScale={{ type: 'linear', min: 0, max: 0.06 }}
        yFormat=" >-.4f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickValues: 20,
          tickPadding: 5,
          tickRotation: 0,
          legend: t<string>('report.age'),
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t<string>('report.volume'),
          legendOffset: -55,
          legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'top-right',
            data: [
              {
                id: "promedio-1",
                color: getColor('promedio-1'),
                label: t<string>('report.avg-1')
              },
              {
                id: "promedio",
                color: getColor('promedio'),
                label: t<string>('report.avg')
              },
              {
                id: "promedio+1",
                color: getColor('promedio+1'),
                label: t<string>('report.avg+1')
              }
            ],
            direction: 'column',
            justify: false,
            translateX: -80,
            translateY: -58,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        gridXValues={20}
        layers={[
          "grid",
          "axes",
          AreaLayer,
          "points",
          "markers",
          "mesh",
          'lines',
          CustomLegend
        ]}

      />
    )
  }

  return (
    <>
      <Row justify='center' align='middle' style={{ marginTop: 20 }}>
        {
          //<Typography.Title level={2}>A chart</Typography.Title>
        }
      </Row>
      <Row justify='center' align='middle' >
        <div style={{ height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 50 }}>
          <CustomLineChart />
        </div>
      </Row>

      <Row justify='center' align='middle' >
        <div style={{ width: '80%', display: 'flex', justifyContent: 'start', alignItems: 'center', margin: 50 }}>
          {
            dataSource != null ?
              <Typography.Title level={4}>{dataSource!.result ? t('report.classifierBad') : t('report.classifierGood')}</Typography.Title>
              :
              null
          }
        </div>
      </Row>

    </>
  );
};

const mapStatetoProps = (state: any) => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStatetoProps)(ReportChart);


