import React, { useEffect, useState } from 'react';
import { Button, Table, InputNumber, Form, Row, Col, Tooltip } from 'antd';
import { FormOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './EditableTable.css'

const initState = {
  dataSource: [{
    key: '0',
    f2: 1500,
    fiability: 100,
    time: 1.5,
    dp: null,
    nf: null,
    snr: null
  },
  {
    key: '1',
    f2: 2000,
    fiability: 100,
    time: 1.5,
    dp: null,
    nf: null,
    snr: null
  },
  {
    key: '2',
    f2: 3000,
    fiability: 100,
    time: 1.5,
    dp: null,
    nf: null,
    snr: null
  },
  {
    key: '3',
    f2: 4000,
    fiability: 100,
    time: 1.5,
    dp: null,
    nf: null,
    snr: null
  },
  {
    key: '4',
    f2: 5000,
    fiability: 100,
    time: 1.5,
    dp: null,
    nf: null,
    snr: null
  },
  {
    key: '5',
    f2: 6000,
    fiability: 100,
    time: 1.5,
    dp: null,
    nf: null,
    snr: null
  },
  ],
};


/**

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext)!;
  const { t } = useTranslation();

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      //console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: t('warning.input'),
          },
        ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  f2: number;
  fiability: number;
  time: number;
  dp: number | null;
  nf: number | null;
  snr: number | null;
}

interface EditableTableState {
  dataSource: DataType[];
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function EditableTable({ setDpoaeInput }: any) {
  const { t } = useTranslation();

  const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'F2',
      dataIndex: 'f2',
      editable: false,
      width: '15%'
    },
    {
      title: 'DP',
      dataIndex: 'dp',
      editable: true,
      width: '15%'
    },
    // Noise floor
    {
      title: 'NF',
      dataIndex: 'nf',
      editable: true,
      width: '15%'
    },
    // Signal noise relationship
    {
      title: 'SNR',
      dataIndex: 'snr',
      editable: true,
      width: '15%'
    },
    {
      title: t('buildReport.fiability'),
      dataIndex: 'fiability',
      editable: true,
      width: '15%'
    },
    {
      title: t('buildReport.time'),
      dataIndex: 'time',
      editable: true,
      width: '15%'
    },
  ];

  const [state, setState] = useState<EditableTableState>({...initState});

  const handleSave = (row: DataType) => {
    const newData = [...state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setState({ ...state, dataSource: newData });
    setDpoaeInput({ ...state, dataSource: newData })
  };


  const { dataSource } = state;
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columnsMapped = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });
  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      dataSource={dataSource}
      columns={columnsMapped as ColumnTypes}
      pagination={false}
      style={{ margin: 30, }}
    />
  );
}


**/




interface Item2 {
  [objKey: string]: any,
  key: string;
  f2: number;
  fiability: number;
  time: number;
  dp: number | null;
  nf: number | null;
  snr: number | null;
}


interface EditableCellProps2 extends React.HTMLAttributes<HTMLElement> {
  editable: boolean;
  dataIndex: string;
  record: Item2;
  index: number;
  children: React.ReactNode;
  handleSave2: (key: React.Key) => void;
}

// https://codesandbox.io/s/antd-simple-editable-table-with-computed-cell-69x4l

function EditableTable2({ setDpoaeInput }: any) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [data, setData] = useState<Item2[]>([...initState.dataSource]);
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    data.forEach(obj => {
      const key = obj.key;
      Object.keys(obj).forEach(elem => form.setFieldsValue({ [key + elem]: obj[elem] }))
    })
  }, [data, form])

  const edit = () => {
    setIsEditing(true);
  };

  const save = async () => {
    try {
      const table = (await form.validateFields()) as Item2;
      const newRows = new Array(data.length).fill(undefined).map((_, index) => {
        return ({
          key: `${index}`,
          f2: data[index].f2,
          fiability: table[index + 'fiability'],
          time: table[index + 'time'],
          dp: table[index + 'dp'],
          nf: table[index + 'nf'],
          snr: table[index + 'snr']
        })
      });

      setData(newRows);
      setIsEditing(false);
      setDpoaeInput({ ...data, dataSource: newRows })

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: <Tooltip placement="top" title={t('buildReport.F2')}>{'F2'}</Tooltip>,
      dataIndex: 'f2',
      editable: false,
      width: '15%'
    },
    {
      title: <Tooltip placement="top" title={t('buildReport.DP')}>{'DP'}</Tooltip>,
      dataIndex: 'dp',
      editable: true,
      width: '15%'
    },
    // Noise floor
    {
      title: <Tooltip placement="top" title={t('buildReport.NF')}>{'NF'}</Tooltip>,
      dataIndex: 'nf',
      editable: true,
      width: '15%'
    },
    // Signal noise relationship
    {
      title: <Tooltip placement="top" title={t('buildReport.SNR')}>{'SNR'}</Tooltip>,
      dataIndex: 'snr',
      editable: true,
      width: '15%'
    },
    {
      title: t('buildReport.fiability'),
      dataIndex: 'fiability',
      editable: true,
      width: '15%'
    },
    {
      title: t('buildReport.time'),
      dataIndex: 'time',
      editable: true,
      width: '15%'
    },
  ];

  const EditableCell2: React.FC<EditableCellProps2> = ({
    editable,
    dataIndex,
    record,
    index,
    children,
    handleSave2,
    ...restProps
  }) => {
    const inputNode = <InputNumber
      onPressEnter={() => handleSave2(record.key)}
    //onBlur={() => handleSave2(record.key)}
    />;

    return (
      <td {...restProps}>
        {isEditing && editable ? (
          <Form.Item
            name={record.key + dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${t('warning.input')}`
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map(col => {
    //if (!col.editable) {
    //  return col;
    //}
    return {
      ...col,

      onCell: (record: Item2) => ({
        record,
        title: '',
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave2: save,
      }),
    };
  });

  return (
    <>
      {
        isEditing ? (
          <Row style={{ marginBottom: 10 }} justify={'start'}>
            <Col>
              <Button onClick={() => save()} style={{ marginRight: 8 }} icon={<SaveOutlined />}>
                {t('buildReport.save')}
              </Button >
            </Col>
            <Col>
              <Button onClick={() => setIsEditing(false)} style={{ marginRight: 8 }} icon={<DeleteOutlined />}>
                {t('buildReport.cancel')}
              </Button >
            </Col>
          </Row>
        ) : (
          <Row style={{ marginBottom: 15 }}>
            <Button onClick={() => edit()} icon={<FormOutlined />}>
              {t('buildReport.edit')}
            </Button>
          </Row>
        )
      }
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell2,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => edit(), // click row
            };
          }}
        />
      </Form>
    </>
  );
};


export default EditableTable2;