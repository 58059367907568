import { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Form, Row, Col, Input, Select, Typography, Button, DatePicker, InputNumber, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DndUpload from './DndUpload';

import EditableTable from './DpoaeInput';
import ReportModal from './BuildReportModal';
import useFetch from '../../hooks/useFetch';

import './styles.css'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

interface IState {
  username: string,
  userNationalId: string,
  ownerDni: string,
  ownerId: string | null,
  centerId: string | null,
  newPatient: boolean,
  patientId: string | null,
  patientNationalId: string,
  patientName: string,
  patientLastName: string,
  birthDate: string,
  sex: string,
  age: number | undefined,
  educationLevel: number | null,
  dpoaeDeviceType: string,
  dpoae: File | null,
  survey: File | null,
  audiometry: File | null,
  moca: File | null,
  mmse: File | null,
}

interface IPatientsState {
  patientId: string | null,
  patientNationalId: string,
  patientName: string,
  patientLastName: string,
  birthDate: string,
  sex: string,
  age: number | undefined,
  educationLevel: number | null,
}

interface DpoaeTableDataType {
  key: React.Key;
  f2: number;
  dp: number | null;
  // noise
  nf: number | null;
  // signal noise relation
  snr: number | null;

  fiability: number;
  time: number;
}

interface IEditableTableState {
  dataSource: DpoaeTableDataType[];
}

const genderState = [{ name: 'male', id: 'm' }, { name: 'female', id: 'f' }, { name: 'noinfo', id: 'n' }]
const dpoaeDeviceType = [{ name: 'Titan', id: 'titan' }]

function BuildReport(props: any) {
  const { t } = useTranslation();
  const [form] = Form.useForm();


  const [fetchOwnerState, setFetchOwnerState] = useFetch();

  // Fetch list of users in the same institution
  const [fetchPatientsState, setFetchPatientsState] = useFetch();

  // Modal state
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState<IState>({
    username: '',
    userNationalId: '',
    ownerDni: '',
    ownerId: null,
    centerId: null,
    newPatient: true,
    patientId: '',
    patientNationalId: '',
    patientName: '',
    patientLastName: '',
    birthDate: '',
    sex: '',
    age: undefined,
    educationLevel: null,
    dpoaeDeviceType: 'titan',
    // Files
    dpoae: null,
    // Optional Files
    survey: null,
    audiometry: null,
    moca: null,
    mmse: null,

  });
  const [ownerState, setOwnerState] = useState([{ name: '', nationalId: '', id: '' }])
  const [centerState, setCenterState] = useState([{ name: '', id: '' }])
  const [patientsState, setPatientsState] = useState<IPatientsState[]>([{ patientId: null, patientNationalId: '', patientName: '', patientLastName: '', birthDate: '', sex: '', age: undefined, educationLevel: null }])

  // Optional info has manual input by default, changing manual to false dont hide the input but show the input plus the upload component
  // DPOAE manual input hide the upload component
  const [documentManualInputState, setDocumentManualInputState] = useState({ survey: true, audiometry: true, moca: true, mmse: true, dpoae: false })
  // If an element inside document state its false, use manual input. 
  const [optionalDocumentsState, setOptionalDocumentsState] = useState<{ [key: string]: { [key: string]: number | null } }>(
    {
      survey: { surveyfield1: null },
      audiometry: { audiometryLeft: null, audiometryRight: null },
      moca: { mocafield1: null },
      mmse: { mmsefield1: null },
    })
  const [dpoaeInputLeft, setDpoaeInputLeft] = useState<IEditableTableState>();
  const [dpoaeInputRight, setdpoaeInputRight] = useState<IEditableTableState>();

  // State used for adtional validations
  const [submitState, setSubmitState] = useState(false)

  /**
   * EFFECTS
   */

  useEffect(() => {
    setState(baseState => {
      return {
        ...baseState,
        username: `${props.firstName} ${props.lastName}`,
        userNationalId: props.nationalId,
      }
    });
    const nullCenter = [{ id: null, name: t('buildReport.N/A'), nationalId: '' }];
    setCenterState([...nullCenter, ...props.institution.center]);
  }, [props.firstName, props.lastName, props.nationalId, props.institution.center, t])


  const fetchStable = useCallback((enpoint: string, setter: any) => {
    const url = `${process.env.REACT_APP_API_URL}${enpoint}`;
    const headers = {
      Authorization: `JWT ${props.token}`
    };
    setter({ url: url, method: 'GET', headers: headers }, true);
  }, [props.token])


  // we have to store the fetch setters cuz use effect use these var in the dependecy array and triggers an infinite loop
  const setFetchOwnerStateStable = useRef(setFetchOwnerState);
  const setFetchPatientsStateStable = useRef(setFetchPatientsState)

  /**
   * Fetch users and patients in the same institution
   */
  useEffect(() => {
    fetchStable('v1/accounts/users/', setFetchOwnerStateStable.current)
    fetchStable('v1/reports/institution/patients/', setFetchPatientsStateStable.current)
  }, [fetchStable])

  /**
   * Fill the select with the fetched data
   */
  useEffect(() => {
    if (fetchOwnerState.status === 'fetched') {
      const ownerArray = fetchOwnerState.data.map((obj: any) => (
        {
          id: obj.id, name: `${obj.first_name} ${obj.last_name}`,
          nationalId: obj.national_id
        })
      );
      const nullOwner = [{ id: null, name: t('buildReport.N/A'), nationalId: '' }];
      setOwnerState([...nullOwner, ...ownerArray]);
    }
    if (fetchPatientsState.status === 'fetched') {
      const patientsArray = fetchPatientsState.data.map((obj: any) => (
        {
          patientId: obj.id,
          patientNationalId: obj.national_id,
          patientName: obj.first_name,
          patientLastName: obj.last_name,
          birthDate: moment(obj!.birth_date, 'DD-MM-YYYY'),
          sex: obj.sex,
          age: moment().diff(moment(obj!.birth_date, 'DD-MM-YYYY'), 'years'),
          educationLevel: obj.educational_level

        })
      );
      setPatientsState(patientsArray)
    }

  }, [fetchOwnerState, fetchPatientsState, t])



  /**
  useEffect(() => {
    console.log(state, "0000")
  }, [state])
  */



  /**
   * Generic handler when an input changes
   * @param name 
   * @param value 
   */
  const handleInputChange = (name: string, value: any) => {
    if (name === 'ownerName') {
      const selectedDni = ownerState.find(obj => obj.id === value)?.nationalId!
      setState({ ...state, ownerDni: selectedDni, ownerId: value })
    }
    else if (name === 'center') {
      const selectedCenter = props.institution.center.find((obj: any) => obj.id === value)?.id!
      setState({ ...state, centerId: selectedCenter })
    }
    else if (name === 'birthDate') {
      const age = moment().diff(moment(value, 'DD-MM-YYYY'), 'years')
      setState({ ...state, [name]: value, age: isNaN(age) ? undefined : age })
      form.setFieldsValue({
        age: age,
      });
    }
    else if (name === 'patientNationalId' && state.newPatient === false) {
      const patient = patientsState.find(obj => obj.patientNationalId === value);
      setState({ ...state, ...patient });
      // When Form.Item + name + rules are used antd uses his own controlled component
      // Set antd state
      form.setFieldsValue({
        patientId: patient?.patientId,
        patientName: patient?.patientName,
        patientLastName: patient?.patientLastName,
        birthDate: moment(patient!.birthDate, 'DD-MM-YYYY'),
        sex: patient?.sex,
        age: patient?.age,
        educationLevel: patient?.educationLevel
      });
    }
    else {
      setState({ ...state, [name]: value })
    }
  }

  /**
   * Handle manual input for optional info 
   * @param fieldName 
   * @param document <moca, mmse, survey, >
   * @param value 
   */
  const handleDocumentInput = (fieldName: string, document: string, value: number) => {
    optionalDocumentsState[document][fieldName] = value;
    setOptionalDocumentsState(optionalDocumentsState);

    if (value != null && document !== 'audiometry') {
      setDocumentManualInputState({ ...documentManualInputState, [document]: false })
    }
    else if (value != null && fieldName === 'audiometryLeft' && optionalDocumentsState.audiometry.audiometryRight != null) {
      setDocumentManualInputState({ ...documentManualInputState, audiometry: false })
    }
    else if (value != null && fieldName === 'audiometryRight' && optionalDocumentsState.audiometry.audiometryLeft != null) {
      setDocumentManualInputState({ ...documentManualInputState, audiometry: false })
    }
    else {
      setDocumentManualInputState({ ...documentManualInputState, [document]: true })
    }
  }

  /**
   * Store file in the form state
   * @param file File
   * @param fileType Dpoae, moca, mmse, etc
   */
  const handleFileUpload = (file: File | null, fileType: string) => {
    setState({ ...state, [fileType]: file })
  }

  /**
   * Force validate file field, so the warning disapears if a file was uploaded
   */
  useEffect(() => {
    if (submitState && !documentManualInputState.dpoae) {
      form.validateFields(['dpoae']);
    }
    if (submitState && documentManualInputState.dpoae) {
      form.validateFields(['dpoaeInputLeft']);
      form.validateFields(['dpoaeInputRight']);
    }
  }
    , [form, state.dpoae, documentManualInputState.dpoae, submitState])

  /**
   * Clean inputs if new patient
   */
  useEffect(() => {
    if (state.username !== '') {
      form.setFieldsValue({
        patientId: null,
        patientNationalId: '',
        patientName: '',
        patientLastName: '',
        birthDate: '',
        sex: '',
        age: undefined,
        educationLevel: null
      });

      setState(oldState => ({
        ...oldState,
        patientId: null,
        patientNationalId: '',
        patientName: '',
        patientLastName: '',
        birthDate: '',
        sex: '',
        age: undefined,
        educationLevel: null
      })
      );

    }
  }
    , [form, state.newPatient, state.username])

  /**
   * Disable date for people older than 100 years and younger than 45
   * @param current 
   * @returns boolean if date disable
   */
  const disabledDate = (current: any) => {
    const yearDiff = moment().diff(current, 'years');;
    if (yearDiff > 100 || yearDiff < 45) {
      return true;
    }
    return false;

  }

  const onChangePatientCheckBox = (checked: boolean) => {
    setState({ ...state, newPatient: checked });
  }

  /**
   * Check that every element in the matrix is non empty
   * @param arr 
   * @returns 
   */
  const checkEmptyFields = (arr: DpoaeTableDataType[] | undefined) => {
    return arr ? Object.values(arr).some(obj => obj.dp !== null && obj.nf !== null && obj.snr !== null) : false
  }


  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  /**
  * Go to report
  */
  const onFinish = () => {
    //process local state and generate the report
    showModal();
  }

  return (
    <div style={{ margin: '3%' }}>
      <Row justify='center'>
        <Typography.Title level={1}> {t('buildReport.buildReport')} </Typography.Title>
      </Row>

      <Form
        name='user-form' {...layout}
        onFinish={() => onFinish()}
        onFinishFailed={() => setSubmitState(true)}
        form={form}
      >
        <Row justify='center' >
          <Col lg={8} >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography.Title level={2}> {t('buildReport.userForm')} </Typography.Title>
            </div>

            <Form.Item label={t('buildReport.userName')}>
              <Input name='username' disabled={true} value={state.username} />
            </Form.Item>
            <Form.Item label={t('buildReport.userId')}>
              <Input name='userNationalId' disabled={true} value={state.userNationalId} />
            </Form.Item>

            <Form.Item
              name={'ownerName'}
              label={t('buildReport.ownerName')}
              //rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
              initialValue={state.ownerId}
            >

              <Select
                onChange={(value) => handleInputChange('ownerName', value)}
                showSearch
                filterOption={(input, selectOption) =>
                  selectOption?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {ownerState.sort((a, b) => a.name.localeCompare(b.name)).map(obj =>
                  <Select.Option key={obj.id} value={obj.id}>
                    {obj.name}
                  </Select.Option>
                )}
              </Select>
            </Form.Item >

            <Form.Item label={t('buildReport.ownerId')}>
              <Input name='ownerDni' disabled={true} placeholder={t('buildReport.ownerId')} value={state.ownerDni} />
            </Form.Item>
            <Form.Item
              name={'center'}
              label={t('buildReport.center')}
              //rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
              initialValue={state.centerId}
            >
              <Select
                onChange={(value) => handleInputChange('center', value)}
                showSearch
                filterOption={(input, selectOption) =>
                  selectOption?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {centerState.sort((a, b) => a.name.localeCompare(b.name)).map(obj =>
                  <Select.Option key={obj.id} value={obj.id}>
                    {obj.name}
                  </Select.Option>
                )}
              </Select>
            </Form.Item >
          </Col>

          <Col lg={8} >
          </Col>
        </Row>

        <Divider />

        <Row justify='center' align='top' >
          <Col lg={8} >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography.Title level={2}> {t('buildReport.requiredFields')} </Typography.Title>
            </div>
            <Form.Item name={'newPatient'} label={t('buildReport.newPatient')}>
              <Checkbox onChange={(event) => onChangePatientCheckBox(event.target.checked)} checked={state.newPatient} />
            </Form.Item>

            <Form.Item
              name={'patientNationalId'}
              label={t('buildReport.patientId')}
              rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
            >
              {
                state.newPatient ?
                  <Input name='patientDni' value={state.patientNationalId} onChange={(event) => handleInputChange('patientDni', event.target.value)} />
                  :
                  <Select
                    onChange={(value) => handleInputChange('patientNationalId', value)}
                    showSearch
                    filterOption={(input, selectOption) =>
                      selectOption?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {patientsState.sort((a, b) => a.patientNationalId.localeCompare(b.patientNationalId)).map(obj =>
                      <Select.Option key={obj.patientNationalId} value={obj.patientNationalId}>
                        {obj.patientNationalId}
                      </Select.Option>
                    )}
                  </Select>
              }
            </Form.Item>

            <Form.Item
              name={'patientName'}
              label={t('buildReport.patientName')}
              rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
            >
              <Input disabled={!state.newPatient} name='patientName' value={state.patientName} onChange={(event) => handleInputChange('patientName', event.target.value)} />
            </Form.Item>
            <Form.Item
              name={'patientLastName'}
              label={t('buildReport.patientLastName')}
              rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
            >
              <Input disabled={!state.newPatient} name='patientLastName' value={state.patientLastName} onChange={(event) => handleInputChange('patientLastName', event.target.value)} />
            </Form.Item>

            <Form.Item
              name={'birthDate'}
              label={t('buildReport.birthDate')}
              rules={[{ type: 'object', required: true, whitespace: true, message: t('warning.input') }]}
            >
              <DatePicker
                disabled={!state.newPatient}
                placeholder='Birth date'
                format='DD-MM-YYYY'
                disabledDate={disabledDate}
                defaultPickerValue={moment().subtract(45, 'years')}
                onChange={(optionalDocumentsState: any, dateStr: string) => handleInputChange('birthDate', dateStr)}
              />
            </Form.Item>
            <Row style={{ marginBottom: 10 }}>
              <Col span={8}>
              </Col>
              <Col span={16}>
                <Typography.Text type='secondary'>{t('buildReport.dateWarning')}</Typography.Text>
              </Col>
            </Row>

            <Form.Item name={'age'} label={t('buildReport.age')}>
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              name={'sex'}
              label={t('buildReport.sex')}
              rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
            >
              <Select disabled={!state.newPatient} onChange={(value) => handleInputChange('sex', value)} value={state.sex}>
                {genderState.map(obj =>
                  <Select.Option key={obj.name} value={obj.name}>
                    {t(`buildReport.${obj.name}`)}
                  </Select.Option>
                )}
              </Select>
            </Form.Item >
            <Form.Item
              name={'educationLevel'}
              label={t('buildReport.educationLevel')}
              rules={[{ required: true, message: t('warning.input') }]}
            >
              <InputNumber disabled={!state.newPatient} min={0} max={35} onChange={(value) => handleInputChange('educationLevel', value)} />
            </Form.Item>
          </Col>
          <Col lg={8} >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography.Title level={2}> {t('buildReport.requiredFiles')} </Typography.Title>
            </div>
            <Form.Item
              name={'dpoaeManualInput'}
              label={t('buildReport.manualInput')}
              initialValue={false}
              valuePropName='checked'
            >
              <Checkbox
                onChange={(event) => setDocumentManualInputState({ ...documentManualInputState, dpoae: event.target.checked })}
                checked={documentManualInputState.dpoae}
              />
            </Form.Item>
            {
              documentManualInputState.dpoae ?
                <>
                  <Form.Item
                    name='dpoaeInputRight'
                    label={t('buildReport.dpoaeInputRight')}
                    //labelCol={{ span: 24 }}
                    //wrapperCol={{ span: 24 }}
                    rules={[{
                      required: true,
                      validator: (rule: any, value: any) => checkEmptyFields(dpoaeInputRight?.dataSource) ? Promise.resolve() : Promise.reject(t('warning.matrixInput'))
                    }]}
                  >
                    <EditableTable setDpoaeInput={setdpoaeInputRight} />
                  </Form.Item>

                  <Form.Item
                    name='dpoaeInputLeft'
                    label={t('buildReport.dpoaeInputLeft')}
                    rules={[{
                      required: true,
                      validator: (rule: any, value: any) => checkEmptyFields(dpoaeInputLeft?.dataSource) ? Promise.resolve() : Promise.reject(t('warning.matrixInput'))
                    }]}
                  >
                    <EditableTable setDpoaeInput={setDpoaeInputLeft} />
                  </Form.Item>

                </>
                :
                <>
                  <Form.Item
                    name={'dpoaeDeviceType'}
                    label={t('buildReport.dpoaeDeviceType')}
                    rules={[{ required: true, whitespace: true, message: t('warning.input') }]}
                    initialValue={state.dpoaeDeviceType}
                  >
                    <Select onChange={(value, option) => handleInputChange('dpoaeDeviceType', value)} >
                      {dpoaeDeviceType.map(obj =>
                        <Select.Option key={obj.name} value={obj.id}>
                          {obj.name}
                        </Select.Option>
                      )}
                    </Select>
                  </Form.Item >
                  <Form.Item
                    name='dpoae'
                    label={t('buildReport.deviceFile')}
                    rules={[{
                      required: true,
                      validator: (rule: any, value: any) => state.dpoae ? Promise.resolve() : Promise.reject(t('warning.input'))
                    }]}
                  >
                    <DndUpload callback={handleFileUpload} fileType={'dpoae'} />
                  </Form.Item>
                </>
            }

          </Col>
        </Row>

        <Divider />

        <Row justify='center' align='bottom' >
          <Col lg={8} >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography.Title level={2}> {t('buildReport.optionalFiles')}</Typography.Title>
            </div>

            <Form.Item
              name={'surveyfield1'}
              label={t('buildReport.surveyfield1')}
            >
              <InputNumber min={0} max={100} onChange={(value) => handleDocumentInput('surveyfield1', 'survey', value)} />
            </Form.Item>
            {
              !documentManualInputState.survey ?
                <Form.Item
                  name={'survey'}
                  label={t('buildReport.survey')}
                >
                  <DndUpload callback={handleFileUpload} fileType={'survey'} />
                </Form.Item> :
                null
            }

            <Form.Item
              name={'audiometryRight'}
              label={t('buildReport.audiometryRight')}
            >
              <InputNumber min={0} max={100} onChange={(value) => handleDocumentInput('audiometryRight', 'audiometry', value)} />
            </Form.Item>
            <Form.Item
              name={'audiometryLeft'}
              label={t('buildReport.audiometryLeft')}
            >
              <InputNumber min={0} max={100} onChange={(value) => handleDocumentInput('audiometryLeft', 'audiometry', value)} />
            </Form.Item>

            {
              !documentManualInputState.audiometry ?
                <Form.Item
                  name={'audiometry'}
                  label={t('buildReport.audiometry')}
                >
                  <DndUpload callback={handleFileUpload} fileType={'audiometry'} />
                </Form.Item> :
                null
            }


            <Form.Item
              name={'mmsefield1'}
              label={t('buildReport.mmsefield1')}
            >
              <InputNumber min={0} max={100} onChange={(value) => handleDocumentInput('mmsefield1', 'mmse', value)} />
            </Form.Item>
            {
              !documentManualInputState.mmse ?
                <Form.Item
                  name={'mmse'}
                  label={t('buildReport.mmse')}
                >
                  <DndUpload callback={handleFileUpload} fileType={'mmse'} />
                </Form.Item> :
                null
            }

            <Form.Item
              name={'mocafield1'}
              label={t('buildReport.mocafield1')}
            >
              <InputNumber min={0} max={100} onChange={(value) => handleDocumentInput('mocafield1', 'moca', value)} />
            </Form.Item>
            {
              !documentManualInputState.moca ?
                <Form.Item
                  name={'moca'}
                  label={t('buildReport.moca')}
                >
                  <DndUpload callback={handleFileUpload} fileType={'moca'} />
                </Form.Item> :
                null
            }

          </Col>

          <Col lg={8} >
            <Form.Item {...tailLayout}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type='primary' style={{ height: 50, width: 120, fontSize: 20 }} htmlType='submit'>
                  {t('commons.submit')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ReportModal
        visible={visible}
        hideModal={hideModal}
        userId={props.userId}
        institution={props.institution.id}
        data={state}
        optionalDocsValues={optionalDocumentsState}
        manualInput={documentManualInputState.dpoae}
        dpoaeDataLeft={dpoaeInputLeft}
        dpoaeDataRight={dpoaeInputRight}
      />
    </div>

  );
};

const mapStatetoProps = (state: any) => {
  return {
    ...state.auth
  }
}

export default connect(mapStatetoProps)(BuildReport);