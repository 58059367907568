import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Select, Button, } from 'antd';
import { useTranslation } from 'react-i18next';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logoutUserAction } from '../actions/authActions'

const { Option } = Select;

function Navbar(props: any) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({ activeKey: '' })

  /*
  useEffect(() => {
    if (!props.authState.authenticated) {
      history.push('/')
    }
  }, [props.authState.authenticated, history]);
  **/

  useEffect(() => {
    setState({ activeKey: location.pathname })
  }, [location])


  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value, (err) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }

  const onClick = (route: string) => history.push(route)
  const logout = () => {
    props.logout()
  }

  const changeView = (key: string) => {
    setState({ activeKey: key })

    switch (key) {
      case 'home':
        onClick('/');
        break;
      case 'patients':
        onClick('/patients');
        break;
      case 'buildreport':
        onClick('/buildreport');
        break;
      case 'profile':
        onClick('/profile');
        break;
      default:
        onClick('/');
    }
  }

  const getActivekeyStyle = (key: string) => {
    return state.activeKey.includes(key) ? { color: "#0ba35c", borderColor: "#0ba35c" } : { border: 'none' }
  }

  return (
    <Row align='middle' justify='space-between'>
      <Col xs={14} sm={14} lg={14}>
        <Button size={'large'} key="home" style={getActivekeyStyle('home')} onClick={() => changeView('home')}>{t('navbar.home')} </Button>
        {props.authState.authenticated ?
          <>
            <Button size={'large'} key="patients" style={getActivekeyStyle('patients')} onClick={() => changeView('patients')}> {t('commons.patientsTable')}</Button>
            <Button size={'large'} key="buildreport" style={getActivekeyStyle('buildreport')} onClick={() => changeView('buildreport')}>{t('commons.newReport')} </Button>
          </>
          :
          null
        }
      </Col >

      <Col xs={10} sm={10} lg={10} style={{flex: "0 0 auto"}}>
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center'}}>

          {props.authState.authenticated ?
            <>
              <Button size={'large'} style={getActivekeyStyle('profile')} onClick={() => changeView('profile')}>
                {t('navbar.profile')}
              </Button>
              <Button size={'large'} onClick={logout} type='link'>
                {t('navbar.logout')}
              </Button>
            </>
            :
            <Button size={'large'} onClick={() => onClick('/login')} type='link'>
              {t('navbar.login')}
            </Button>
          }

          <Select defaultValue={t(`navbar.${i18n.language}`)} onChange={changeLanguage}>
            <Option value='en-US'>{t('navbar.en-US')}</Option>
            <Option value='es-ES'>{t('navbar.es-ES')}</Option>
          </Select>
        </div>
      </Col>
    </Row >
  );
};

const mapStatetoProps = (state: any) => {
  return {
    authState: state.auth
  }
}

const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    logout: () => {
      dispatch(logoutUserAction())
    }
  }
}
export default connect(mapStatetoProps, mapDispatchtoProps)(Navbar);