import { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import useFetch from '../../hooks/useFetch';

import { Modal, Button } from 'antd';

import moment from 'moment';

const SubmitModal = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  // Fetch build report
  const [fetchState, setFetchState] = useFetch();
  // Fetch list of users in the same institution
  // Upload optional files

  useEffect(() => {
    setModalText(t('buildReport.processingData'));
  }, [t])

  /**
  * Build request body before fetching data
  * @returns 
  */
  const buildRequest = useCallback(() => {
    const data = props.data;
    let reqBody: any = {};
    if (data.patientId !== null && !data.newPatient) {
      reqBody['patient'] = data.patientId;
    }
    else {
      const patientData = {
        "first_name": data.patientName,
        "last_name": data.patientLastName,
        "birth_date": moment((data.birthDate), 'DD-MM-YYYY').format('YYYY-MM-DD'),
        "national_id": data.patientDni,
        "sex": data.sex,
        "educational_level": data.educationLevel,
        "institution": props.institution

      };
      reqBody['patient_data'] = JSON.stringify(patientData);
    };

    reqBody['report_user'] = props.userId;

    if (data.ownerId !== null) {
      reqBody['report_owner'] = data.ownerId;
    }
    if (data.centerId !== null) {
      reqBody['center'] = JSON.stringify(data.centerId);
    }

    reqBody['manual_input'] = props.manualInput;

    // Send form with manual input data
    if (props.manualInput) {
      reqBody['dpoae_left'] = JSON.stringify(props.dpoaeDataLeft.dataSource);
      reqBody['dpoae_right'] = JSON.stringify(props.dpoaeDataRight.dataSource);
    }
    // Send file to parse
    else {
      reqBody['dpoae_file'] = data.dpoae;
    }
    return reqBody;
  }, [props]  );

  // Little trick, we know that this function never change and we don't want to trigger the effect
  const setFetchStateStable = useRef(setFetchState);

  // TODO update dependency array with missing dependecies using useCallback or useRef
  useEffect(() => {
    const handleOpen = () => {
      setConfirmLoading(true);
      setModalText(t('buildReport.processingData'));
      let formData = new FormData();
      const requestBody = buildRequest();
      Object.keys(requestBody).forEach(key => {
        formData.append(key, requestBody[key])
      })
      const url = `${process.env.REACT_APP_API_URL}v1/reports/`;
      const headers = {
        Authorization: `JWT ${props.token}`
      };
      setFetchStateStable.current({ url: url, method: 'POST', headers: headers, body: formData }, true);
    };

    if (props.visible) {
      setModalText(t('buildReport.processingData'));
      handleOpen();
    }
  }, [props.visible, props.token, t, buildRequest])

  /**
   * If fetchState change (report created succesfully), upload aditiona files if needed
   */
  useEffect(() => {
    if (fetchState.status === 'fetched' && fetchState.statusCode < 300) {
      setModalText(t('buildReport.processedData'));
      setConfirmLoading(false);


      // Upload optional files if exist
      const optionalDocs = ['survey', 'audiometry', 'moca', 'mmse',]
      // Check the docs to fetch
      const optionalDocsObj = props.optionalDocsValues;
      const fetchArray = optionalDocs.filter(elem => Object.values(optionalDocsObj[elem]).every(x => x !== null));

      // If no optional docs/data found dont fetch
      if (fetchArray.length === 0) {
        return
      }
      const headers = {
        Authorization: `JWT ${props.token}`
      };
      const parameters = {
        method: 'PUT',
        headers: headers
      }

      const paramArray = fetchArray.map(elem => {
        let formData = new FormData();
        if (optionalDocsObj[elem] !== null) {
          formData.append('file', props.data[elem]);
        }
        formData.append('patient', fetchState.data.patient);
        formData.append('type', elem.toUpperCase());
        formData.append('additional_info', JSON.stringify(optionalDocsObj[elem]));
        return formData;

      })

      Promise.all(
        paramArray.map(bodyData =>
          fetch(`${process.env.REACT_APP_API_URL}v1/reports/upload_file/`, { ...parameters, body: bodyData })
            .then((response) => {
              return response.json();
            }).then((data) => {
              return data;
            })
        ))
        .then(() => {
        }).catch(() =>
          setModalText(t('buildReport.genericError'))
        );
    }
    if (fetchState.status === 'fetched' && fetchState.statusCode >= 400) {
      fetchState.data.error === 'constraint' ? setModalText(t('buildReport.constraintError')) : setModalText(t('buildReport.genericError'));
      setConfirmLoading(false);
    }
  }, [fetchState, props.data, props.optionalDocsValues, props.token, t])

  const handleCancel = () => {
    props.hideModal();
  };

  const handleOK = () => history.push(`/report/${fetchState.data.id}`);


  return (
    <>
      <Modal
        title={(t('buildReport.buildingReport'))}
        visible={props.visible}
        onOk={handleOK}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={
          fetchState.data && fetchState.statusCode < 400 ?
            [
              <Button type="primary" onClick={handleOK} key={'ok'}>
                {(t('buildReport.continue'))}
              </Button>
            ]
            :
            null
        }>
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

const mapStatetoProps = (state: any) => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStatetoProps)(SubmitModal);